import {TableBase} from "../../tables/table_base";

class InfringementsPerDay extends TableBase {
  constructor() {
    super('infringements_per_day');
  }

  paintTable($infringementScore, infringements_per_day) {
    $infringementScore.empty()
    let noInfringementScores = true

    for (const driver in infringements_per_day) {
      const id = infringements_per_day[driver].id;
      const score = infringements_per_day[driver].score;
      const $row = $('<tr>');

      const $infoCell = $('<span>').attr({'data-tbl': 'usr', 'data-id': id}).addClass('hover-preview').text(driver);
      const $regCell = $('<td>').append($infoCell);
      const $dataCell = $('<td>').text(score.toFixed(2));

      $row.append($regCell).append($dataCell);
      $infringementScore.append($row);

      noInfringementScores = false
    }

    if( noInfringementScores ){
      $infringementScore.html('<tr><td colspan="5">No infringements scores to display</td></tr>');
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new InfringementsPerDay();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
